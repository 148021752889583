import React, { useEffect, useState, useRef } from 'react';
import Aos from 'aos';
import 'aos/dist/aos.css';
import './index.css';

import Navbar from './component/navbar';
import Footer from './component/footer';
import Card from './component/card';

import herovideo from './assets/hero.mp4';
import img1 from './assets/matt3x.png';
import img2 from './assets/nexion.png';
import img3 from './assets/mars.png';

function App() {

  useEffect(() =>{
    Aos.init({ duration: 1200 });
  },[]);

  const [Opacity, SetOpacity] = useState({ opacity: 1 });

  useEffect(() =>{
    let animationFrameId;
    const Animations = () =>{
      //const scrollTop = window.scrollY;

      const NewOpacity = Math.max(1 - window.scrollY / 400, 0);
      SetOpacity({ opacity: NewOpacity });


    };

    const onScroll = () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      animationFrameId = requestAnimationFrame(Animations);
    };
    window.addEventListener('scroll', Animations);
    return () => {
      if (animationFrameId) cancelAnimationFrame(animationFrameId);
      window.removeEventListener('scroll', onScroll);
    };
  },[]);

  const [username] = useState("lori______");
  const [alert, SetAlert] = useState(false);
  const [AlertOnline, SetAlertOnline] = useState(false);
  const [AlertOffline, SetAlertOffline] = useState(false);

  const handleCopy = () =>{
    navigator.clipboard.writeText(username)
    
    SetAlert(!alert);

    setTimeout(() => {
      SetAlert(false);
    }, 2000);

  };

  const handleAlertOnline = () => {
    SetAlertOnline(true);
    setTimeout(() => {
      SetAlertOnline(false);
    }, 2000);
  };

  const handleAlertOffline = () => {
    SetAlertOffline(true);
    setTimeout(() => {
      SetAlertOffline(false);
    }, 2000);
  };

  return (
    <div className='App'>
      <header>
        <Navbar />
      </header>
      
      <div className={`${alert ? 'Alert Open' : 'hidden'}`}>
        <h1 className='text-white text-xl font-bold'>Username copiato.</h1>
      </div>

      <div style={{ opacity: Opacity.opacity }}>
          <video className='hero-video' autoPlay loop muted>
            <source src={herovideo} type='video/mp4'/>
          </video>
      </div>

      <section className='Section'>
        <div className='relative z-[2] pb-10' style={{ opacity: Opacity.opacity }}>
          <h1 className='text-white font-extrabold text-md' data-aos="fade-right">Ciao, sono</h1>
          <h1 className='text-[#4E3016] font-extrabold text-8xl lg:text-9xl' style={{letterSpacing: "10px"}} 
          data-aos="fade-left" data-aos-delay="150">LORI</h1>
        </div>
      </section>
      
      <section className='Section Content'>
        <div className='m-4' data-aos="fade-up" data-aos-delay="0">
          <h1 className='text-[#4E3016] font-bold text-2xl'>PROJECTS</h1>
        </div>
        <div className='container-grid'>
            <div data-aos="fade-up" data-aos-delay="200">
              <Card
                img = {img3}
                titolo = "Mars Template"
                link = "#"
                desc = "Scopri il template Mars. Layout responsive, design minimalista e performance ottimizzate per adattarsi a qualsiasi dispositivo."
                stato = "Offline"
                statoclick = {handleAlertOffline}
              />
            </div>
            <div data-aos="fade-up" data-aos-delay="400">
              <Card
                img = {img1}
                titolo = "Matt3x Modding"
                link = "matt3xmodding.web.app"
                desc = "MATT3X_GAMER_COMMUNITY è il posto perfetto per chi condivide la passione per i soccorsi e le forze dell'ordine. e acquistare modelli per FlveM."
                stato = "Online"
                statoclick = {handleAlertOnline}
              />
            </div>
            <div data-aos="fade-up" data-aos-delay="600">
              <Card
                img = {img2}
                titolo = "Nexion RP"
                link = "nexion-rp.web.app"
                desc = "Nexion RP è il server perfetto per chi cerca un'esperienza di roleplay immersiva e realistica su FiveM. Unisciti a una community appassionata, pronta a vivere avventure uniche."
                stato = "Online"
                statoclick = {handleAlertOnline}
              />
            </div>
        </div>
      </section>
        
      <section className='Section Content'>
        <div className='m-4 break-words' data-aos="fade-up">
          <h1 className='text-[#4E3016] font-bold text-2xl'>CONTACTS</h1>
        </div>
        <div data-aos="fade-up" data-aos-delay="200">
          <p className='text-xl text-white font-semibold text-center md:pl-20 md:pr-20'>
            Se sei interessato a uno dei template o desideri creare un sito web personalizzato, non esitare a contattarmi su Discord. Sarò felice di aiutarti a realizzare il tuo progetto!
          </p>
        </div>
        <div className='m-4 flex justify-center items-center' data-aos="zoom-in" data-aos-delay="400">
          <div className='p-2 rounded-full bg-zinc-900 hover:scale-[1.1] transition-all cursor-pointer' onClick={handleCopy}>
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#4E3016" className="bi bi-discord" viewBox="0 0 16 16">
              <path d="M13.545 2.907a13.2 13.2 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.2 12.2 0 0 0-3.658 0 8 8 0 0 0-.412-.833.05.05 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.04.04 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032q.003.022.021.037a13.3 13.3 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019q.463-.63.818-1.329a.05.05 0 0 0-.01-.059l-.018-.011a9 9 0 0 1-1.248-.595.05.05 0 0 1-.02-.066l.015-.019q.127-.095.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.05.05 0 0 1 .053.007q.121.1.248.195a.05.05 0 0 1-.004.085 8 8 0 0 1-1.249.594.05.05 0 0 0-.03.03.05.05 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.2 13.2 0 0 0 4.001-2.02.05.05 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.03.03 0 0 0-.02-.019m-8.198 7.307c-.789 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612m5.316 0c-.788 0-1.438-.724-1.438-1.612s.637-1.613 1.438-1.613c.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612"/>
            </svg>
          </div>
        </div>
      </section>

      <div className={`${AlertOffline ? 'AlertProject-Offline Open' : 'hidden'}`}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-info" viewBox="0 0 16 16">
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
          </svg>
        </div>
        <h2 className='text-2xl font-bold'>OFFLINE</h2>
      </div>
      <div className={`${AlertOnline ? 'AlertProject-Online Open' : 'hidden'}`}>
        <div>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="white" class="bi bi-info" viewBox="0 0 16 16">
            <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
          </svg>
        </div>
        <h2 className='text-2xl font-bold'>ONLINE</h2>
      </div>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}

export default App;