import React, { Component } from "react";
import '../index.css';

class Card extends Component {

    render() {
        return(
            <div className="bg-zinc-900 rounded-2xl relative flex flex-col justify-center items-center p-8">
                <img src={this.props.img} className="w-full h-full rounded-2xl mx-auto" alt="img"/>
                <div className="m-4">
                    <a href={this.props.link} target="_blank" className="text-white text-2xl font-bold cursor-pointer">{this.props.titolo}</a>
                </div>
                <div className="break-words w-full max-w-md">
                    <p className="text-white text-sm font-semibold">
                        {this.props.desc}
                    </p>
                </div>
                <div className={`${this.props.stato}`} onClick={this.props.statoclick}></div>
            </div>
        );
    }
}

export default Card;