import React from "react";
import '../index.css';

const HeroVideoLink = "https://www.pexels.com/it-it/video/ricerca-galleggiante-atmosfera-pianeta-7615703/";

function Footer() {
    return(
        <div className="Footer">
            <div className="m-4">
                <h1 className="text-white text-xl font-extrabold" style={{letterSpacing: "5px"}}>LORI</h1>
            </div>
            <div className="flex justify-center items-center">
                <hr className="w-full"></hr>
            </div>
            <div className="flex flex-col justify-center items-center">
                <div className="m-4">
                    <a href={HeroVideoLink} target="_blank" className="text-white font-semibold text-sm hover:text-blue-700 transition-all">Home video | Kindel Media da Pexels</a>
                </div>
                <div>
                    <a href="" target="_blank" className="m-4 text-white font-semibold text-sm">LORI - All Rights Reserved. 2024 &copy;</a> 
                </div>
            </div>
        </div>
    );
}

export default Footer;