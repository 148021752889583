import React, { useState } from "react";
import '../index.css';

const link = "https://cdn.discordapp.com/avatars/980889160317542410/62948480664c307bdf958f693f808c0c.webp?size=100";

function Navbar() {

    const [nav, SetNav] = useState(false);

    const NavFunction = () =>{
        SetNav(!nav);
    };

    const handleHome = () =>{
        window.scrollTo({
            top: 1, 
            behavior: "smooth"
          });
          SetNav(!nav);
    };
    const handleProjects = () =>{
        window.scrollTo({
            top: 700, 
            behavior: "smooth"
          });
          SetNav(!nav);
    };
    const handleContacs = () =>{
        window.scrollTo({
            top: 1500, 
            behavior: "smooth"
          });
          SetNav(!nav);
    };

    return(
        <>
        <div className={`Navbar`}>
            <div className={`${nav ? 'NavButton' : ''} cursor-pointer hover:scale-[0.9] transition-all`} onClick={NavFunction}>
                <img src={link} className="h-16 w-16 rounded-2xl" alt="logo"/>
            </div>
        </div>
        
        <div className={`${nav ? 'Open flex' : 'hidden'} bg-[#0A090B] w-full h-screen fixed top-[0%] left-[0%] flex-col justify-center items-center z-[900]`}>
            <ul className="flex flex-col text-center gap-4">
                <a className="text-[#4E3016] text-xl font-bold uppercase hover:text-white transition-all cursor-pointer" onClick={handleHome}>Home</a>
                <a className="text-[#4E3016] text-xl font-bold uppercase hover:text-white transition-all cursor-pointer" onClick={handleProjects}>Projects</a>
                <a className="text-[#4E3016] text-xl font-bold uppercase hover:text-white transition-all cursor-pointer" onClick={handleContacs}>Contacts</a>
            </ul>
        </div>

        </>
    );
}

export default Navbar;